import { useDispatch } from "react-redux";
import { PageTracker } from "../../util/pageTracker";
import AppStyles from "../../styles/appStyles";
import { Box, Button, Grid, Paper, Table, TableBody, TableCell, TableHead, TableRow, useTheme } from "@mui/material";
import intl from "react-intl-universal";
import * as React from "react";
import { useEffect, useState } from "react";
import { HeaderLabel } from "../../components/header/HeaderLabel";
import { DefaultButton } from "../../components/Buttons";
import * as FamilyBonusClubActions from "../../actions/family.bonus.club.actions";
import { FbcOffer, FbcOfferReviewReason } from "../../model/fbc/fbc.offer";
import moment from "moment/moment";
import { useNavigate } from "react-router-dom";

export default function FamilyBonusClubInboxPage() {
  const [offers, setOffers] = useState<FbcOffer[]>([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  function reviewOffer({ offer_id }: { offer_id: string }) {
    navigate(`/family-bonus-club-offer-inbox/${offer_id}`);
  }
  const theme = useTheme();

  useEffect(() => {
    dispatch<any>(
      FamilyBonusClubActions.fetchBonusClubInbox({
        completion: (loadedOffers) => {
          setOffers(loadedOffers);
        },
      }),
    );
  }, []);

  return (
    <>
      <PageTracker />

      <Box sx={AppStyles.innerContentRootWithoutLimit}>
        <Grid container direction="row" justifyContent="space-between" alignItems="flex-end">
          <Grid item sx={AppStyles.headerLeft}>
            <HeaderLabel>{intl.get("family_bonus_club.inbox.title")}</HeaderLabel>
          </Grid>
          <Grid item sx={AppStyles.headerRight}>
            <DefaultButton sx={AppStyles.buttonPlaceholder} />
          </Grid>
        </Grid>

        <Paper sx={AppStyles.innerContentPaper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="none" sx={AppStyles.tableHeaderCell}>
                  {intl.get("family_bonus_club.inbox.list_headers.offer_title")}
                </TableCell>
                <TableCell padding="none" sx={AppStyles.tableHeaderCell}>
                  {intl.get("family_bonus_club.inbox.list_headers.entry_title")}
                </TableCell>
                <TableCell padding="none" sx={AppStyles.tableHeaderCell}>
                  {intl.get("family_bonus_club.inbox.list_headers.company")}
                </TableCell>
                <TableCell padding="none" sx={AppStyles.tableHeaderCell} align="right">
                  {intl.get("family_bonus_club.inbox.list_headers.submission_time")}
                </TableCell>
                <TableCell padding="none" sx={AppStyles.tableHeaderCell} align="right">
                  {intl.get("family_bonus_club.inbox.list_headers.action")}
                </TableCell>
                <TableCell padding="none" sx={AppStyles.tableHeaderCell} align="right"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {offers.map((offer) => {
                return (
                  <TableRow
                    key={offer.offer_id}
                    onClick={() => {
                      reviewOffer({ offer_id: offer.offer_id });
                    }}
                  >
                    <TableCell scope="row" padding="none" sx={AppStyles.tableCell}>
                      {offer.draft_offer.title}
                    </TableCell>
                    <TableCell scope="row" padding="none" sx={AppStyles.tableCell}>
                      {offer.event_title}
                    </TableCell>
                    <TableCell scope="row" padding="none" sx={AppStyles.tableCell}>
                      {offer.company_name}
                    </TableCell>
                    <TableCell scope="row" padding="none" sx={AppStyles.tableCell} align="right">
                      {moment(new Date()).diff(moment(offer.review.review_submitted_at)) > 1000 * 60 * 60 * 8
                        ? moment(offer.review.review_submitted_at).locale("de").format("LL LT")
                        : moment(offer.review.review_submitted_at).fromNow()}
                    </TableCell>
                    <TableCell
                      scope="row"
                      padding="none"
                      sx={{
                        ...AppStyles.tableCell,
                        color:
                          offer.review.review_reason === FbcOfferReviewReason.reviewToDeactivate
                            ? theme.palette.error.main
                            : undefined,
                      }}
                      align="right"
                    >
                      {offer.review.review_reason === FbcOfferReviewReason.reviewToActivate
                        ? intl.get("family_bonus_club.inbox.actions.review_to_activate")
                        : intl.get("family_bonus_club.inbox.actions.review_to_deactivate")}
                    </TableCell>
                    <TableCell
                      padding="none"
                      sx={AppStyles.tableCell}
                      align="right"
                      style={{ width: "200px !important" }}
                    >
                      <Button
                        variant="outlined"
                        style={{ marginRight: 10 }}
                        onClick={() => {
                          reviewOffer({ offer_id: offer.offer_id });
                        }}
                      >
                        {intl.get("family_bonus_club.inbox.actions.review")}
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Paper>
      </Box>
    </>
  );
}
