import { DialogHandler } from "../../components/dialog/useDialogHandler";
import HandlerDialog from "../../components/dialog/handlerDialog";
import * as React from "react";
import {
  Box,
  Button,
  Checkbox,
  CheckboxProps,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Theme,
} from "@mui/material";
import intl from "react-intl-universal";
import * as FamilyBonusClubActions from "../../actions/family.bonus.club.actions";
import { useState } from "react";
import { useDispatch } from "react-redux";
import useSelectedCompany from "../../hooks/useSelectedCompany";
import { LinkButton } from "../../components/Buttons";

const classes = {
  link: (theme: Theme) => ({
    color: theme.palette.primary.main,
  }),
};

const SignupCheckbox = (props: CheckboxProps) => {
  return (
    <Checkbox
      sx={(theme) => ({
        color: "#6E7CE0",
        "& .Mui-checked": {
          color: theme.palette.primary.main,
          fontFamily: theme.fontFamily,
        },
      })}
      color="primary"
      {...props}
    />
  );
};

export default function DialogJoinFamilyBonusClub({
  handler,
  onFinishJoining,
  onCancel,
}: {
  handler: DialogHandler<void>;
  onFinishJoining: () => void;
  onCancel: () => void;
}) {
  return (
    <HandlerDialog handler={handler} fullWidth={true} maxWidth="sm">
      <Content onCancel={onCancel} onFinishJoining={onFinishJoining} />
    </HandlerDialog>
  );
}

function Content({ onFinishJoining, onCancel }: { onFinishJoining: () => void; onCancel: () => void }) {
  const dispatch = useDispatch();
  const company = useSelectedCompany();

  const [showJoinSuccess, setShowJoinSuccess] = useState(false);
  const [isJoiningClub, setIsJoiningClub] = useState(false);
  const [hasAcceptedTerms, setHasAcceptedTerms] = useState(false);
  const [hasShownTerms, setHasShownTerms] = useState(false);

  const signupPossible = () => {
    return hasAcceptedTerms && hasShownTerms;
  };

  function joinBonusClub() {
    setIsJoiningClub(true);
    dispatch<any>(
      FamilyBonusClubActions.joinBonusClub({
        company_id: company.company_id,
        completion: () => {
          setIsJoiningClub(false);
          setShowJoinSuccess(true);
        },
      }),
    );
  }

  return (
    <>
      <DialogTitle id="alert-dialog-title">
        <Box
          sx={(theme) => ({
            color: "#3c3c3b",
            fontWeight: 800,
            fontFamily: theme.headerFontFamily,
            fontSize: theme.fontSizes.largeFont,
          })}
        >
          {showJoinSuccess && intl.get("family_bonus_club.join.success_title")}
          {!showJoinSuccess && intl.get("family_bonus_club.join.title")}
        </Box>
      </DialogTitle>
      <DialogContent>
        {showJoinSuccess && intl.get("family_bonus_club.join.success_message")}
        {!showJoinSuccess && (
          <Grid item container alignItems="center">
            <SignupCheckbox checked={hasAcceptedTerms} onChange={() => setHasAcceptedTerms(!hasAcceptedTerms)} />
            <Box
              component={"a"}
              href="https://blue-cherries.com/teilnahmebedingungen-fbc/"
              target="_blank"
              sx={classes.link}
              onClick={() => {
                setHasShownTerms(true);
              }}
              dangerouslySetInnerHTML={{ __html: intl.get("family_bonus_club.join.accept_terms") }}
            ></Box>
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        {showJoinSuccess && (
          <>
            <Button
              onClick={() => {
                onFinishJoining();
              }}
              color="secondary"
              autoFocus
            >
              {intl.get("family_bonus_club.join.success_close_action")}
            </Button>
          </>
        )}
        {!showJoinSuccess && (
          <>
            <LinkButton
              title={intl.get("family_bonus_club.join.cancel_action")}
              onClick={onCancel}
              disabled={isJoiningClub}
            />
            <Button
              disabled={isJoiningClub || !signupPossible()}
              onClick={() => {
                joinBonusClub();
              }}
              color="primary"
              autoFocus
            >
              {intl.get("family_bonus_club.join.join_action")}
            </Button>
          </>
        )}
      </DialogActions>
    </>
  );
}
