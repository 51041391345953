import { Grid, Stack } from "@mui/material";
import { connect } from "react-redux";
import * as React from "react";
import { useState } from "react";
import { bindActionCreators, compose } from "redux";
import * as intl from "react-intl-universal";
import { RootState } from "../../reducers";
import * as CompanyActions from "../../actions/company";
import * as EventActions from "../../actions/event";
import { EventState, EventUpgradeSlotsInfo } from "../../model/eventState";
import { CompanyState } from "../../model/companyState";
import { BCEvent } from "../../model/event";
import { AuthenticationState } from "../../model/authenticationState";
import { PageTracker } from "../../util/pageTracker";
import { useNavigate } from "react-router-dom";
import AppStyles from "../../styles/appStyles";
import { HeaderLabel } from "../../components/header/HeaderLabel";
import { DefaultButton } from "../../components/Buttons";
import AddIcon from "@mui/icons-material/Add";
import PlanPromotionBanner from "../../components/company/PlanPromotionBanner";
import DialogDeleteCompanyEventConfirmation from "../../components/company/DialogDeleteCompanyEventConfirmation";
import CompanyEventsGrid from "../../components/company/CompanyEventsGrid";
import useDialogHandler from "../../components/dialog/useDialogHandler";
import CompanyEventsList from "../../components/company/CompanyEventsList";
import { EventCardActionEvent } from "../../components/events/EventCard";
import CallToActionHeaderBanner, { OnboardingImageType } from "../../components/events/CallToActionHeaderBanner";
import ProductUpgradesInformationBanner from "../../components/booking/ProductUpgradesInformationBanner";
import TablePaginationFloating from "../../components/TablePaginationFloating";
import NewsletterDrawer from "../../components/drawer/newsletter/NewsletterDrawer";
import SocialMediaDrawer from "../../components/drawer/social/SocialMediaDrawer";
import BookingDrawer, { BookingDrawerHandlerPayload } from "../../components/drawer/BookingDrawer";
import AvailableEventUpgradeSlotsButton from "../../components/events/AvailableEventUpgradeSlotsButton";
import BulkEventUpgradeDrawer, {
  BulkEventUpgradeDrawerPayload,
} from "../../components/drawer/bulkEventUpgrade/BulkEventUpgradeDrawer";
import useCompanyEvents from "../../hooks/useCompanyEvents";
import CompanyEventsViewTypeTabs, { CompanyEventsViewType } from "./CompanyEventsViewTypeTabs";
import useEventUpgradeSlotsInfo from "../../hooks/useEventUpgradeSlotsInfo";
import WarningMessageBanner from "../../components/events/WarningMessageBanner";
import DialogFamilyBonusClubEventOffers, {
  DialogFamilyBonusClubEventOffersPayload,
} from "../fbc/DialogFamilyBonusClubEventOffers";
import DialogJoinFamilyBonusClub from "../fbc/DialogJoinFamilyBonusClub";

const DEFAULT_PAGE_SIZE = 50;

function TopPlacementSlotsButton({ upgradeSlotsInfo }: { upgradeSlotsInfo: EventUpgradeSlotsInfo }) {
  const upgradeType = upgradeSlotsInfo.premium_plus_available > 0 ? "premium_plus" : "premium";
  const handler = useDialogHandler<BulkEventUpgradeDrawerPayload>();

  const numberOfTypeSlots =
    upgradeType === "premium_plus" ? upgradeSlotsInfo.premium_plus_available : upgradeSlotsInfo.premium_available;

  const handleButtonClick = () => {
    if (numberOfTypeSlots > 0) {
      handler.open({
        upgradeType,
        numberOfSlots: numberOfTypeSlots,
      });
    }
  };

  return (
    <>
      <AvailableEventUpgradeSlotsButton upgradeSlotsInfo={upgradeSlotsInfo} onClick={handleButtonClick} />
      <BulkEventUpgradeDrawer handler={handler} />
    </>
  );
}

export interface Props {
  eventState: EventState;
  companyState: CompanyState;
  actions: typeof CompanyActions;
  eventActions: typeof EventActions;
  authenticationState: AuthenticationState;
}

export interface State {
  event: BCEvent;
  showUpgradeChangeDialog: boolean;
  showUpgradeSlotsUnavailableDialog: boolean;
  eventUpgradeOption: string;
  eventIdForUpdatingStatus?: string;
}

function CompanyEventsPage(props: Props) {
  const navigate = useNavigate();

  const [viewType, setViewType] = useState<CompanyEventsViewType>("list");

  const deleteEventDialogHandler = useDialogHandler<BCEvent>("deleteCompanyEvent");
  const newsletterDrawerHandler = useDialogHandler<BCEvent>("newsletterDrawer");
  const socialMediaDrawerHandler = useDialogHandler<BCEvent>("socialMediaDrawer");
  const bookingDrawerHandler = useDialogHandler<BookingDrawerHandlerPayload>("bookingDrawer");

  const familyBonusClubOffersDialogHandler =
    useDialogHandler<DialogFamilyBonusClubEventOffersPayload>("familyBonusClubOffers");

  const joinFamilyBonusClubDialogHandler = useDialogHandler<void>("joinFamilyBonusClubDialog");

  const showAddEvent = props.companyState.company.status !== "DELETED";

  const eventUpgradeSlotsInfo = useEventUpgradeSlotsInfo();

  const { events, paginationHandler, pagingInformation, loading: eventsLoading } = useCompanyEvents();

  const showFamilyBonusClubOnboarding =
    props.companyState.company.family_bonuscard.has_joined_fbc === false &&
    !eventsLoading &&
    events.length > 0 &&
    showAddEvent;

  const showPlanPromotionBanner =
    props.companyState.company.number_of_active_subscriptions !== undefined &&
    props.companyState.company.number_of_active_subscriptions === 0;

  const handleEventAction: EventCardActionEvent = (event, action) => {
    switch (action) {
      case "transfer":
        transferEvent(event);
        break;
      case "delete":
        deleteEventDialogHandler.open(event);
        break;
      case "view_event":
        editEvent(event);
        break;
      case "hero":
        bookingDrawerHandler.open({ event, companyId: props.companyState.selected_company_id, intent: "hero_slots" });
        break;
      case "newsletter":
        newsletterDrawerHandler.open(event);
        break;
      case "social_media":
        socialMediaDrawerHandler.open(event);
        break;
      case "top_placement":
        // when the user only has one upgrade option (either premium or premium plus), it is directly handled within the button component
        bookingDrawerHandler.open({
          event,
          companyId: props.companyState.selected_company_id,
          intent:
            eventUpgradeSlotsInfo.premium_available && eventUpgradeSlotsInfo.premium_plus_available
              ? "top_placement"
              : "premium_purchase",
        });
        break;
      case "family_bonus_club":
        if (props.companyState.company.family_bonuscard.has_joined_fbc) {
          if (event.offers.length === 0) {
            addNewFamilyBonusClubOffer({ event });
          } else {
            familyBonusClubOffersDialogHandler.open({ event, companyId: props.companyState.company.company_id });
          }
        } else {
          joinFamilyBonusClub();
        }
        break;
      default:
        break;
    }
  };

  function addNewFamilyBonusClubOffer({ event }: { event: BCEvent }) {
    navigate(`/companies/${props.companyState.selected_company_id}/family-bonus-club/new/${event.event_id}`);
  }

  function addNewEvent() {
    navigate(`/companies/${props.companyState.selected_company_id}/company_events/new`);
  }

  function transferEvent(event: BCEvent) {
    navigate(`/companies/${props.companyState.selected_company_id}/company_events/${event.event_id}/transfer`);
  }

  function joinFamilyBonusClub() {
    joinFamilyBonusClubDialogHandler.open();
    //navigate(`/companies/${props.companyState.selected_company_id}/join-family-bonus-club`);
  }

  function deleteEvent(event: BCEvent) {
    props.eventActions.deleteEvent(props.companyState.selected_company_id, event.event_id);
    deleteEventDialogHandler.close();
  }

  function editEvent(event: BCEvent) {
    if (props.authenticationState.user.role === "REVIEWER") {
      navigate(`/companies/${props.companyState.selected_company_id}/company_events/${event.event_id}/review`);
    } else {
      navigate(`/companies/${props.companyState.selected_company_id}/company_events/${event.event_id}/edit`);
    }
  }

  return (
    <>
      <PageTracker />

      {props.companyState.company.has_overdue_invoices === true && (
        <WarningMessageBanner text={intl.get("company.warning_about_overdue_invoices")} />
      )}

      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <Grid item sx={AppStyles.headerLeft} flexGrow={1}>
          <HeaderLabel>{intl.get("company_events.title")}</HeaderLabel>
        </Grid>
        <Grid item sx={AppStyles.headerMid}>
          <Stack direction={"row"} gap={"22px"}>
            {(eventUpgradeSlotsInfo?.premium_plus_total > 0 || eventUpgradeSlotsInfo?.premium_total > 0) && (
              <TopPlacementSlotsButton upgradeSlotsInfo={eventUpgradeSlotsInfo} />
            )}
            <CompanyEventsViewTypeTabs value={viewType} onChange={setViewType} />
          </Stack>
        </Grid>
        <Grid item sx={AppStyles.headerRight}>
          {showAddEvent && (
            <DefaultButton
              color={"secondary"}
              size={"large"}
              onClick={() => {
                addNewEvent();
              }}
            >
              <AddIcon />
              {intl.get("company_events.button_add")}
            </DefaultButton>
          )}
          {!showAddEvent && <DefaultButton sx={AppStyles.buttonPlaceholder} />}
        </Grid>
      </Grid>

      <Stack sx={{ padding: 2 }} gap={2}>
        {showAddEvent && !eventsLoading && events.length === 0 && (
          <CallToActionHeaderBanner
            title={intl.get("company_events.onboarding_card.title")}
            text={intl.get("company_events.onboarding_card.text")}
            actionTitle={intl.get("company_events.onboarding_card.call_to_action")}
            imageType={OnboardingImageType.eventOnboarding}
            onAction={() => {
              addNewEvent();
            }}
          />
        )}

        {showFamilyBonusClubOnboarding && (
          <CallToActionHeaderBanner
            title={intl.get("family_bonus_club.onboarding_card.title")}
            text={intl.get("family_bonus_club.onboarding_card.text")}
            actionTitle={intl.get("family_bonus_club.onboarding_card.call_to_action")}
            imageType={OnboardingImageType.familyBonusCardOnboarding}
            onAction={() => {
              joinFamilyBonusClub();
            }}
          />
        )}

        {showPlanPromotionBanner && <PlanPromotionBanner companyId={props.companyState.selected_company_id} />}
        {viewType === "grid" && <CompanyEventsGrid onActionClicked={handleEventAction} />}
        {viewType === "list" && <CompanyEventsList onActionClicked={handleEventAction} />}

        {showAddEvent && <ProductUpgradesInformationBanner />}
      </Stack>

      {pagingInformation.total_size > DEFAULT_PAGE_SIZE && <TablePaginationFloating handler={paginationHandler} />}

      <NewsletterDrawer handler={newsletterDrawerHandler} />
      <SocialMediaDrawer handler={socialMediaDrawerHandler} />

      <BookingDrawer handler={bookingDrawerHandler} />

      <DialogDeleteCompanyEventConfirmation handler={deleteEventDialogHandler} onConfirm={deleteEvent} />

      <DialogFamilyBonusClubEventOffers handler={familyBonusClubOffersDialogHandler} />
      <DialogJoinFamilyBonusClub
        handler={joinFamilyBonusClubDialogHandler}
        onFinishJoining={() => {
          joinFamilyBonusClubDialogHandler.close();
        }}
        onCancel={() => {
          joinFamilyBonusClubDialogHandler.close();
        }}
      />
    </>
  );
}

function mapStateToProps(state: RootState) {
  return {
    eventState: state.eventState,
    companyState: state.companyState,
    authenticationState: state.authenticationState,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    actions: bindActionCreators(CompanyActions as any, dispatch),
    eventActions: bindActionCreators(EventActions as any, dispatch),
  };
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(CompanyEventsPage);
