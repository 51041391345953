import axios from "axios";
import { Dispatch } from "redux";
import { mapErrorFromException } from "../model/error";
import { ActionType } from "../model/actions";
import { FbcOffer, FbcOfferData } from "../model/fbc/fbc.offer";
import intl from "react-intl-universal";
import { PagingInformation } from "../model/pagingInformation";

export const joinBonusClub =
  ({ company_id, completion }: { company_id: string; completion: () => void }) =>
  async (dispatch: Dispatch) => {
    try {
      const res = await axios.post(`/v2/api/b2b/private/companies/${company_id}/family-bonus-club`, {
        participate_in_all_cards: true,
      });

      dispatch({
        type: ActionType.SAVE_COMPANY,
        payload: { company: res.data },
      });
      completion();
    } catch (error) {
      dispatch({
        type: ActionType.PAGE_SAVING_ERROR,
        payload: { error: mapErrorFromException(error) },
      });
    }
  };

export const fetchBonusClubInbox =
  ({ completion }: { completion: (offers: FbcOffer[]) => void }) =>
  async (dispatch: Dispatch) => {
    try {
      const res = await axios.get(`/v2/api/b2b/private/family-bonus-club-offer-inbox`);
      completion(res.data.offers as FbcOffer[]);
    } catch (error) {
      dispatch({
        type: ActionType.PAGE_LOADING_ERROR,
        payload: { error: mapErrorFromException(error) },
      });
    }
  };

export const approveOffer =
  ({ offer_id, completion }: { offer_id: string; completion: (success: boolean) => void }) =>
  async (dispatch: Dispatch) => {
    dispatch({ type: ActionType.PAGE_SAVING });

    try {
      const res = await axios.put(`/v2/api/b2b/private/family-bonus-club-offer-inbox/${offer_id}/review`, {
        result: "APPROVE",
      });
      if (res.data.status === "ok") {
        dispatch({
          type: ActionType.REVIEWED_FAMILY_BONUS_CLUB_OFFER,
        });
        dispatch({ type: ActionType.PAGE_SAVED });
        completion(true);
      } else {
        dispatch({
          type: ActionType.PAGE_SAVING_ERROR,
          payload: { error: { errorMessage: intl.get(`family_bonus_club.review.error_review`) } },
        });
      }
    } catch (error) {
      dispatch({
        type: ActionType.PAGE_SAVING_ERROR,
        payload: { error: mapErrorFromException(error) },
      });
    }
  };

export const rejectOffer =
  ({ offer_id, reason, completion }: { offer_id: string; reason: string; completion: (success: boolean) => void }) =>
  async (dispatch: Dispatch) => {
    dispatch({ type: ActionType.PAGE_SAVING });

    try {
      const res = await axios.put(`/v2/api/b2b/private/family-bonus-club-offer-inbox/${offer_id}/review`, {
        result: "REJECT",
        feedback: reason,
      });
      if (res.data.status === "ok") {
        dispatch({
          type: ActionType.REVIEWED_FAMILY_BONUS_CLUB_OFFER,
        });
        dispatch({ type: ActionType.PAGE_SAVED });
        completion(true);
      } else {
        dispatch({
          type: ActionType.PAGE_SAVING_ERROR,
          payload: { error: { errorMessage: intl.get(`family_bonus_club.review.error_review`) } },
        });
      }
    } catch (error) {
      dispatch({
        type: ActionType.PAGE_SAVING_ERROR,
        payload: { error: mapErrorFromException(error) },
      });
    }
  };

export const loadOfferForReview =
  ({ offer_id, completion }: { offer_id: string; completion: (offer: FbcOffer) => void }) =>
  async (dispatch: Dispatch) => {
    try {
      const res = await axios.get(`/v2/api/b2b/private/family-bonus-club-offer-inbox/${offer_id}`);
      completion(res.data);
    } catch (error) {
      dispatch({
        type: ActionType.PAGE_LOADING_ERROR,
        payload: { error: mapErrorFromException(error) },
      });
    }
  };

export const fetchEventsWithFamilyBonusClubOffers =
  ({
    company_id,
    page,
    size,
    completion,
  }: {
    company_id: string;
    page: number;
    size: number;
    completion: (events: [], paging: PagingInformation) => void;
  }) =>
  async (dispatch: Dispatch) => {
    dispatch({ type: ActionType.PAGE_LOADING });

    try {
      const res = await axios.get(
        `/v2/api/b2b/private/companies/${company_id}/events?page=${page}&size=${size}&with_fbc_offers_only=true`,
      );
      completion(res.data.events, res.data.paging);
      dispatch({ type: ActionType.PAGE_LOADED });
    } catch (error) {
      dispatch({
        type: ActionType.PAGE_LOADING_ERROR,
        payload: { error: mapErrorFromException(error) },
      });
    }
  };

export const fetchOffer =
  ({
    company_id,
    offer_id,
    completion,
  }: {
    company_id: string;
    offer_id: string;
    completion: (offer: FbcOffer) => void;
  }) =>
  async (dispatch: Dispatch) => {
    dispatch({ type: ActionType.PAGE_LOADING });

    try {
      const res = await axios.get(`/v2/api/b2b/private/companies/${company_id}/family-bonus-club-offers/${offer_id}`);

      completion(res.data);
      dispatch({ type: ActionType.PAGE_LOADED });
    } catch (error) {
      dispatch({
        type: ActionType.PAGE_LOADING_ERROR,
        payload: { error: mapErrorFromException(error) },
      });
    }
  };

export const saveFamilyBonusClubOffer =
  ({
    company_id,
    offer_id,
    event_id,
    data,
    completion,
  }: {
    company_id: string;
    offer_id?: string;
    event_id?: string;
    data: FbcOfferData;
    completion: () => void;
  }) =>
  async (dispatch: Dispatch) => {
    dispatch({ type: ActionType.PAGE_SAVING });

    try {
      if (offer_id) {
        await axios.put(`/v2/api/b2b/private/companies/${company_id}/family-bonus-club-offers/${offer_id}`, data);
      } else {
        await axios.post(
          `/v2/api/b2b/private/companies/${company_id}/events/${event_id}/family-bonus-club-offers`,
          data,
        );
      }

      dispatch({ type: ActionType.PAGE_SAVED });
      completion();
    } catch (error) {
      dispatch({
        type: ActionType.PAGE_SAVING_ERROR,
        payload: { error: mapErrorFromException(error) },
      });
    }
  };

export const deleteFamilyBonusClubOffer =
  ({ company_id, offer_id, completion }: { company_id: string; offer_id: string; completion: () => void }) =>
  async (dispatch: Dispatch) => {
    dispatch({ type: ActionType.PAGE_SAVING });
    try {
      await axios.delete(`/v2/api/b2b/private/companies/${company_id}/family-bonus-club-offers/${offer_id}`);

      dispatch({ type: ActionType.PAGE_SAVED });
      completion();
    } catch (error) {
      dispatch({
        type: ActionType.PAGE_SAVING_ERROR,
        payload: { error: mapErrorFromException(error) },
      });
    }
  };
