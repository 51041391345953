import useDialogHandler, { DialogHandler } from "../../components/dialog/useDialogHandler";
import { BCEvent } from "../../model/event";
import HandlerDialog from "../../components/dialog/handlerDialog";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  Table,
  TableCell,
  TableHead,
  TableRow,
  useTheme,
} from "@mui/material";
import intl from "react-intl-universal";
import * as React from "react";
import AppStyles from "../../styles/appStyles";
import {
  FbcOffer,
  FbcOfferStatus,
  formattedStatusColorForOffer,
  formattedStatusForOffer,
} from "../../model/fbc/fbc.offer";
import { DefaultButton, LinkButton } from "../../components/Buttons";
import useDialogReset from "../../components/dialog/useDialogReset";
import useBackendLoader from "../../hooks/useBackendLoader";
import { useNavigate } from "react-router-dom";
import moment from "moment/moment";
import DialogDeleteFamilyBonusClubOfferConfirmation from "./DialogDeleteFamilyBonusClubOfferConfirmation";
import { useDispatch } from "react-redux";
import * as FamilyBonusClubActions from "../../actions/family.bonus.club.actions";
import AddIcon from "@mui/icons-material/Add";

interface EventOffersBackendResponse {
  offers: FbcOffer[];
}

export interface DialogFamilyBonusClubEventOffersPayload {
  event?: BCEvent;
  companyId: string;
}
export default function DialogFamilyBonusClubEventOffers({
  handler,
}: {
  handler: DialogHandler<DialogFamilyBonusClubEventOffersPayload>;
}) {
  const eventId = handler.payload?.event?.event_id;
  const companyId = handler.payload?.companyId;
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const loader = useBackendLoader<EventOffersBackendResponse, FbcOffer[]>(
    `/v2/api/b2b/private/companies/${companyId}/events/${eventId}/family-bonus-club-offers`,
    (result) => result.offers,
    "EventOffers",
  );

  useDialogReset(handler, () => {
    loader.reload();
  });

  const deleteFamilyBonusClubOfferHandler = useDialogHandler<FbcOffer>("deleteOffer");

  async function deleteFamilyBonusClubOffer({ offer_id }: { offer_id: string }) {
    dispatch<any>(
      FamilyBonusClubActions.deleteFamilyBonusClubOffer({
        company_id: companyId,
        offer_id,
        completion: () => {
          loader.reload();
        },
      }),
    );
  }

  return (
    <HandlerDialog handler={handler} fullWidth={true} maxWidth="lg">
      <DialogTitle id="alert-dialog-title">
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Grid item flexGrow={1}>
            <Box
              sx={(theme) => ({
                color: "#3c3c3b",
                fontWeight: 800,
                fontFamily: theme.headerFontFamily,
                fontSize: theme.fontSizes.mediumFont,
              })}
            >
              {intl.get("family_bonus_club.event_offers_dialog.title", { event_title: handler.payload?.event.title })}
            </Box>
          </Grid>

          <Grid item>
            <Stack direction={"row"} gap={"22px"}></Stack>
          </Grid>
          <Grid item>
            <DefaultButton
              color={"secondary"}
              size={"large"}
              onClick={() => {
                navigate(`/companies/${companyId}/family-bonus-club/new/${eventId}`);
              }}
            >
              <AddIcon />
              {intl.get("family_bonus_club.offers.action_add")}
            </DefaultButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        {!loader.loading && loader.data && (
          <>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={AppStyles.tableCellNotSelectable}>
                    {intl.get("family_bonus_club.event_offers_list.list_header_title")}
                  </TableCell>
                  <TableCell sx={AppStyles.tableCellNotSelectable}>
                    {intl.get("family_bonus_club.event_offers_list.list_header_status")}
                  </TableCell>
                  <TableCell sx={AppStyles.tableCellNotSelectable} align="right">
                    {intl.get("family_bonus_club.event_offers_list.list_header_create_date")}
                  </TableCell>
                  <TableCell sx={AppStyles.tableCellNotSelectable} align="right">
                    {intl.get("family_bonus_club.event_offers_list.list_header_update_date")}
                  </TableCell>
                  <TableCell align="right" />
                </TableRow>
              </TableHead>

              {loader.data.map((value) => {
                return (
                  <FamilyBonusClubOfferTableRow
                    withCreateDate={true}
                    offer={value}
                    onEdit={() => {
                      navigate(`/companies/${companyId}/family-bonus-club/${value.offer_id}/edit`);
                    }}
                    onDelete={() => {
                      deleteFamilyBonusClubOfferHandler.open(value);
                    }}
                  />
                );
              })}
            </Table>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            handler.close();
          }}
          color="primary"
        >
          {intl.get("family_bonus_club.event_offers_dialog.close_action")}
        </Button>
      </DialogActions>

      <DialogDeleteFamilyBonusClubOfferConfirmation
        handler={deleteFamilyBonusClubOfferHandler}
        onConfirm={(offer) => {
          deleteFamilyBonusClubOffer({ offer_id: offer.offer_id });
          deleteFamilyBonusClubOfferHandler.close();
        }}
      />
    </HandlerDialog>
  );
}

export function FamilyBonusClubOfferTableRow({
  offer,
  onEdit,
  onDelete,
  withCreateDate,
}: {
  offer: FbcOffer;
  onEdit: () => void;
  onDelete: () => void;
  withCreateDate: boolean;
}) {
  const theme = useTheme();
  return (
    <TableRow>
      {offer.draft_offer && (
        <>
          <TableCell>{offer.draft_offer.title}</TableCell>
          <TableCell sx={{ color: formattedStatusColorForOffer({ offer, theme }) }} align="right">
            {formattedStatusForOffer({ offer })}
          </TableCell>
          {withCreateDate && (
            <TableCell align="right">
              {moment(new Date()).diff(moment(offer.draft_offer.created_at)) > 1000 * 60 * 60 * 8
                ? moment(offer.draft_offer.created_at).locale("de").format("LL LT")
                : moment(offer.draft_offer.created_at).fromNow()}
            </TableCell>
          )}
          <TableCell align="right">
            {moment(new Date()).diff(moment(offer.draft_offer.updated_at)) > 1000 * 60 * 60 * 8
              ? moment(offer.draft_offer.updated_at).locale("de").format("LL LT")
              : moment(offer.draft_offer.updated_at).fromNow()}
          </TableCell>
        </>
      )}
      {offer.draft_offer === undefined && offer.published_offer !== undefined && (
        <>
          <TableCell>{offer.published_offer.title}</TableCell>
          <TableCell sx={{ color: formattedStatusColorForOffer({ offer, theme }) }} align="right">
            {formattedStatusForOffer({ offer })}
          </TableCell>
          {withCreateDate && (
            <TableCell align="right">
              {moment(new Date()).diff(moment(offer.published_offer.created_at)) > 1000 * 60 * 60 * 8
                ? moment(offer.published_offer.created_at).locale("de").format("LL LT")
                : moment(offer.published_offer.created_at).fromNow()}
            </TableCell>
          )}
          <TableCell align="right">
            {moment(new Date()).diff(moment(offer.published_offer.updated_at)) > 1000 * 60 * 60 * 8
              ? moment(offer.published_offer.updated_at).locale("de").format("LL LT")
              : moment(offer.published_offer.updated_at).fromNow()}
          </TableCell>
        </>
      )}

      <TableCell align={"right"}>
        {(offer.draft_offer === undefined || offer.draft_offer.status !== FbcOfferStatus.inactive) && (
          <LinkButton
            color={"error"}
            title={intl.get("family_bonus_club.event_offers_list.action_delete")}
            onClick={(event) => {
              event.stopPropagation();
              onDelete();
            }}
          />
        )}
        {!(offer.draft_offer === undefined || offer.draft_offer.status !== FbcOfferStatus.inactive) && (
          <LinkButton
            sx={{ opacity: 0 }}
            color={"error"}
            title={intl.get("family_bonus_club.event_offers_list.action_delete")}
            onClick={() => {}}
          />
        )}
        <LinkButton
          title={intl.get("family_bonus_club.event_offers_list.action_edit")}
          onClick={(event) => {
            event.stopPropagation();
            onEdit();
          }}
        />
      </TableCell>
    </TableRow>
  );
}
